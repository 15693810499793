<template>
  <DetailContact />
  <formContact />
</template>
<script>
import DetailContact from "@/components/contact/DetailContactApp.vue";
import formContact from "@/components/contact/FormContactApp.vue";

export default {
  name: "ContactView",
  components: {
    DetailContact,
    formContact,
  },
};
</script>
