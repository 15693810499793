<template>
  <section class="container">
    <div class="sectionHeader">
      <div class="sectionHeading">
        <h3>Projekty MIŚ</h3>
      </div>
    </div>
    <div class="projectWrapperApp">
      <div
        class="projectItem"
        data-aos="fade-right"
        data-aos-delay="250"
        v-for="item in projectList"
        :key="item.id"
      >
        <div class="projectImg">
          <img :src="item.foto" :alt="item.title" />
        </div>
        <h5 class="projectTitle">{{ item.title }}</h5>
        <p class="projectDesc">
          {{ item.descriptionMain }}
        </p>
        <div class="projectPropertyBox">
          <div class="projectPropertyBoxT">
            <p>Pow. podłogi</p>
            <div class="projectPropertyBox2">
              <i class="ri-calculator-line"></i>
              <p>{{ item.surface }}</p>
            </div>
          </div>
          <div>
            <p>Pow. zabudowy</p>
            <div class="projectPropertyBox2">
              <i class="ri-shape-2-line"></i>
              <p>{{ item.buildingArea }}</p>
            </div>
          </div>
          <div>
            <p>Liczba pokoi</p>
            <div class="projectPropertyBox2">
              <i class="ri-group-fill"></i>
              <p>{{ item.rooms }}</p>
            </div>
          </div>
          <div>
            <p>Cena</p>
            <div class="projectPropertyBox2">
              <p>{{ item.price }}</p>
            </div>
          </div>
        </div>
        <div class="projectButton">
          <a :href="`projekt/${item.id}`">Więcej</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ProjectApp",
  data() {
    return {
      projectList: [],
    };
  },
  methods: {
    async getListProject() {
      try {
        const response = await this.$axios.get("?api&action=getListProject");
        this.projectList = response.data;
      } catch (error) {
        console.error("Błąd:", error);
      }
    },
  },
  mounted() {
    this.getListProject();
  },
  watch: {},
  computed: {},
};
</script>
<style>
.projectWrapperApp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 40px;

  .projectItem {
    max-width: 49%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    text-align: center;

    .projectImg {
      overflow: hidden;

      img {
        width: auto;
        height: 410px;
        transition: 0.3s ease-out;
      }

      img:hover {
        transform: scale(1.1); /* Powiększenie obrazu po najechaniu */
      }
    }

    .projectTitle {
      font-size: 24px;
      margin-top: 10px;
    }

    .projectDesc {
      padding-bottom: 20px;
    }

    .projectButton {
      background: var(--secondary-color);
      padding: 20px;
      border-radius: 20px;
      width: 175px;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      margin: 20px auto;
      transition: background-color 0.5s ease;
    }

    .projectButton:hover {
      background-color: var(--black-color);
    }
  }
}

.projectPropertyBox {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  font-size: 14px;
  position: relative;
}

.projectPropertyBoxT::before {
  content: "";
  height: 3px;
  width: 200px;
  background-color: var(--secondary-color);
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  bottom: 0px;
  text-align: center;
  margin: 0 auto;
}

.projectPropertyBox2 {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 567px) {
  .projectWrapperApp {
    flex-wrap: nowrap;
    flex-direction: column;
    row-gap: 20px;
    .projectItem {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1199px) {
}
</style>
