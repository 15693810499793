<template>
  <section class="questionsWrapper">
    <div class="container">
      <div class="questionsBox" data-aos="flip-left" data-aos-delay="250">
        <div class="questionsTitle">Masz pytania ?</div>
        <div class="questionsDesc">
          Porozmawiaj z naszym Doradcą <br />
          Zadzwoń {{ contactList[0]?.phone }}
        </div>
        <div class="questionsButton">
          <a href="tel:+48511599344">Zadzwoń</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "FooterApp",
  data() {
    return {
      contactList: [],
    };
  },
  methods: {
    async getListContact() {
      try {
        const response = await this.$axios.get("?api&action=getListContact");
        this.contactList = response.data;
      } catch (error) {
        console.error("Błąd:", error);
      }
    },
  },
  mounted() {
    this.getListContact();
  },
  watch: {},
  computed: {},
};
</script>
<style>
.questionsWrapper {
  background: var(--black-color);
  padding: 60px 0px;
  color: var(--white-color);

  .questionsBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    .questionsTitle {
      font-size: 24px;
    }

    .questionsButton {
      background: var(--secondary-color);
      padding: 20px;
      border-radius: 20px;
      width: 175px;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      transition: background-color 0.5s ease;
    }

    .questionsButton:hover {
      background-color: var(--white-color);
    }

    .questionsButton:hover a {
      color: var(--black-color);
    }
  }
}

@media screen and (max-width: 567px) {
  .questionsWrapper {
    .questionsBox {
      flex-direction: column;
      row-gap: 30px;
    }
  }
}
</style>
