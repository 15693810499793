<template>
  <header class="header">
    <nav class="nav container">
      <div class="navBrand">
        <a href="/" class="navLogo">
          <img src="../assets/logo1.png" />
        </a>
        <div
          class="navToggle"
          id="navToggle"
          @click="showMenu('navToggle', 'navMenu')"
        >
          <i class="ri-menu-line navBurger"></i>
          <i class="ri-close-line navClose"></i>
        </div>
      </div>
      <div class="navMenu" id="navMenu">
        <ul class="navList">
          <li
            v-for="itemMenu in listMenu"
            :key="itemMenu.id"
            :class="{
              dropdownItem: itemMenu.children && itemMenu.children.length,
            }"
          >
            <!-- Elementy dla podmenu -->
            <template v-if="itemMenu.children && itemMenu.children.length">
              <div class="navLink">
                Nasze projekty
                <i class="ri-arrow-down-s-line dropdown__arrow"></i>
              </div>
              <ul class="dropdownMenu">
                <li
                  v-for="itemSubMenu in itemMenu.children"
                  :key="itemSubMenu.id"
                >
                  <a :href="`/${itemSubMenu.url}`" class="dropdownLink">
                    <i class="ri-home-4-line"></i> {{ itemSubMenu.title }}
                  </a>
                </li>
              </ul>
            </template>
            <!-- Elementy bez submenu -->
            <template v-else>
              <a :href="`/${itemMenu.url}`" class="navLink">{{
                itemMenu.title
              }}</a>
            </template>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: "HeaderApp",
  data() {
    return {
      listMenu: [],
    };
  },
  created() {},
  methods: {
    showMenu(toggleId, navId) {
      /*=============== SHOW MENU ===============*/
      const toggle = document.getElementById(toggleId),
        nav = document.getElementById(navId);
      console.log(toggle);

      // Add show-menu class to nav menu
      nav.classList.toggle("show-menu");

      // Add show-icon to show and hide the menu icon
      toggle.classList.toggle("show-icon");
    },
    async getListMenu() {
      try {
        const response = await this.$axios.get("?api&action=getListMenu");
        this.listMenu = response.data;
        console.log(this.listMenu);
      } catch (error) {
        console.error("Błąd:", error);
      }
    },
  },
  mounted() {
    this.getListMenu();
  },
  computed: {},
};
</script>

<style>
/*=============== HEADER ===============*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--black-color);
  box-shadow: 0 2px 16px hsla(220, 32%, 8%, 0.3);
  z-index: var(--z-fixed);
}

/*=============== NAV ===============*/
.nav {
  height: calc(var(--header-height) + 2rem);
}

.navLogo,
.navBurger,
.navClose {
  color: var(--white-color);
}

.navBrand {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navLogo {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  font-weight: var(--font-semi-bold);

  & img {
    width: auto;
    height: 75px;
  }
}

.navLogo i {
  font-weight: initial;
  font-size: 1.25rem;
}

.navToggle {
  position: relative;
  width: 32px;
  height: 32px;
}

.navBurger,
.navClose {
  position: absolute;
  width: max-content;
  height: max-content;
  inset: 0;
  margin: auto;
  font-size: 1.25rem;
  cursor: pointer;
  transition: opacity 0.1s, transform 0.4s;
}

.navClose {
  opacity: 0;
}

.navLink {
  color: var(--white-color);
  background-color: var(--black-color);
  font-weight: var(--font-semi-bold);
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  transition: background-color 0.3s;
}

.navLink:hover {
  background-color: var(--black-color-light);
}

/*=============== DROPDOWN ===============*/
.dropdownItem {
  cursor: pointer;
}

.dropdown__arrow {
  font-size: 1.25rem;
  font-weight: initial;
  transition: transform 0.4s;
}

.dropdownLink {
  padding: 1.25rem 1.25rem 1.25rem 2.5rem;
  color: var(--white-color);
  background-color: var(--black-color-light);
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: var(--font-semi-bold);
  transition: background-color 0.3s;
}

.dropdownLink i {
  font-size: 1.25rem;
  font-weight: initial;
}

.dropdownLink:hover {
  background-color: var(--black-color);
}

.dropdownMenu {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

/* Show dropdown menu & submenu */
.dropdownItem:hover .dropdownMenu {
  max-height: 1000px;
  transition: max-height 0.4s ease-in;
}

/* Rotate dropdown icon */
.dropdownItem:hover .dropdown__arrow {
  transform: rotate(180deg);
}

/*=============== DROPDOWN SUBMENU ===============*/
.dropdown__add {
  margin-left: auto;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 567px) {
  .navLink {
    padding-inline: 1rem;
  }
}

/* Navigation for mobile devices */
@media screen and (max-width: 1199px) {
  .navMenu {
    position: absolute;
    left: 0;
    top: 2.5rem;
    width: 100%;
    height: calc(100vh - 3.5rem);
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    transition: top 0.4s, opacity 0.3s;
  }
  .navMenu::-webkit-scrollbar {
    width: 0;
  }
  .navList {
    background-color: var(--black-color);
    padding-top: 1rem;
  }
}

/* For large devices */
@media screen and (min-width: 1200px) {
  .nav {
    display: flex;
    justify-content: space-between;
  }
  .navToggle {
    display: none;
  }
  .navList {
    height: 100%;
    display: flex;
    column-gap: 3rem;
  }
  .navLink {
    height: 100%;
    padding: 0;
    justify-content: initial;
    column-gap: 0.25rem;
  }
  .navLink:hover {
    background-color: transparent;
  }

  .dropdownItem {
    position: relative;
  }

  .dropdownMenu {
    max-height: initial;
    overflow: initial;
    position: absolute;
    left: 0;
    top: 6rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s, top 0.3s;
  }

  .dropdownLink {
    padding-inline: 1rem 3.5rem;
  }

  /* Show dropdown menu */
  .dropdownItem:hover .dropdownMenu {
    opacity: 1;
    top: 5.5rem;
    pointer-events: initial;
    transition: top 0.3s;
  }
}
/* Show menu */
.show-menu {
  opacity: 1;
  top: 5.5rem;
  pointer-events: initial;
}
/* Show icon */
.show-icon .navBurger {
  opacity: 0;
  transform: rotate(90deg);
}
.show-icon .navClose {
  opacity: 1;
  transform: rotate(90deg);
}
</style>
