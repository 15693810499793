<template>
  <swiper
    :slidesPerView="1"
    :slidesPerGroup="1"
    :spaceBetween="1"
    :parallax="false"
    :preventInteractionOnTransition="true"
    :modules="modules"
    :autoplay="{ delay: 5000 }"
    :effect="'slide'"
    :speed="600"
    :loop="false"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    class="swiper-container"
  >
    <swiper-slide
      class="swiperImage"
      v-for="(item, index) in sliderList"
      :key="index"
      :style="{
        backgroundImage: `url(${item.foto})`,
      }"
      :class="{ swiperAnimateImage: isActive(index) }"
    >
      <div class="overlay">
        <div v-html="item.description"></div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Zoom } from "swiper/modules";

// Import Swiper styles
import "swiper/css";

export default {
  name: "SliderApp",
  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    return {
      modules: [Autoplay, Zoom],
    };
  },
  data() {
    return {
      sliderList: [],
      activeIndex: 0,
    };
  },
  methods: {
    onSwiper(swiper) {
      // Przechowujemy instancję Swiper
      this.swiperInstance = swiper;
      console.log("Swiper instance:", swiper);
    },
    onSlideChange(swiper) {
      // Aktualizujemy aktywny indeks
      this.activeIndex = swiper.activeIndex;
      console.log("Current slide index:", swiper.activeIndex);
    },

    async fetchData() {
      try {
        const response = await this.$axios.get("?api&action=getListSlider");
        this.sliderList = response.data;
      } catch (error) {
        console.error("Błąd:", error);
      }
    },
    isActive(index) {
      return index === this.activeIndex;
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {},
  computed: {},
};
</script>

<style>
/* Styl dla slidera */
.swiper-container {
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  overflow: hidden;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
}
.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 90px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  padding: 0 20px;
  text-shadow: #000 4px 3px 5px;
  line-height: 110px;

  & span {
    color: var(--secondary-color);
  }
}
.swiperImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
}

.swiperAnimateImage {
  animation: scale 20s linear infinite;
}

@keyframes scale {
  50% {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 567px) {
  .overlay {
    font-size: 32px;
    line-height: 45px;
  }
}
</style>
