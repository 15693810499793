import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

const app = createApp(App);

// zmienne globalne
app.config.globalProperties.$fancybox = Fancybox;
app.config.globalProperties.$aos = AOS.init({
  duration: 2500,
});
axios.defaults.baseURL = "https://domy.mis.info.pl/admin/api"; // Globalny adres API
app.config.globalProperties.$axios = axios;

app.use(router);
app.mount("#app");
