<template>
  <respect />
  <project />
  <questions />
  <reviews />
</template>

<script>
// @ is an alias to /src
import respect from "@/components/home/RespectApp.vue";
import project from "@/components/home/ProjectApp.vue";
import questions from "@/components/home/QuestionsApp.vue";
import reviews from "@/components/home/CustomerReviewsApp.vue";

export default {
  name: "HomeView",
  components: {
    respect,
    project,
    questions,
    reviews,
  },
};
</script>
