<template>
  <section class="container">
    <div class="sectionHeader container">
      <div class="sectionHeading">
        <h3>{{ aboutList[1]?.title }}</h3>
      </div>
    </div>
    <div class="aboutWrapper">
      <div
        class="aboutBox"
        data-aos="fade-right"
        data-aos-delay="250"
        v-html="aboutList[1]?.firstDesc"
      ></div>
      <div
        class="aboutBox aboutBoxImg"
        data-aos="fade-right"
        data-aos-delay="250"
      >
        <img :src="aboutList[1]?.firstFoto" />
      </div>
      <div
        class="aboutBox aboutBoxImg"
        data-aos="fade-right"
        data-aos-delay="250"
      >
        <img :src="aboutList[1]?.secondFoto" />
      </div>
      <div
        class="aboutBox"
        data-aos="fade-right"
        data-aos-delay="250"
        v-html="aboutList[1]?.secondDesc"
      ></div>
    </div>
  </section>
</template>
<script>
export default {
  name: "TechnologyApp",
  data() {
    return {
      aboutList: [],
    };
  },
  methods: {
    async getListAbout() {
      try {
        const response = await this.$axios.get("?api&action=getListAbout");
        this.aboutList = response.data;
      } catch (error) {
        console.error("Błąd:", error);
      }
    },
  },
  mounted() {
    this.getListAbout();
  },
  watch: {},
  computed: {},
};
</script>
<style>
.aboutWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;

  .aboutBox {
    width: 50%;
    line-height: 33px;
    img {
      border-radius: 20px;
      width: 90%;
    }

    ul {
      li {
        i {
          color: var(--secondary-color);
          margin-right: 5px;
        }
      }
    }
  }
}

.aboutBoxImg {
  text-align: center;
}

@media screen and (max-width: 567px) {
  .aboutWrapper {
    flex-wrap: nowrap;
    flex-direction: column;
    .aboutBox {
      width: 100%;
    }
  }
}
</style>
