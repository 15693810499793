<template>
  <section>
    <div class="sectionHeader">
      <div class="sectionHeading">
        <h3>Formularz kontaktowy</h3>
      </div>
    </div>
    <div class="container">
      <div class="contactFormWrapper" data-aos="fade-up" data-aos-delay="250">
        <form @submit.prevent="submitForm">
          <div class="messageStatus" v-if="statusMessage">
            {{ statusMessage }}
          </div>
          <label for="name">Imię i Nazwisko:</label>
          <input type="text" id="name" v-model="formData.name" required />

          <div class="input-group">
            <div>
              <label for="phone">Telefon:</label>
              <input type="tel" id="phone" v-model="formData.phone" required />
            </div>
            <div>
              <label for="email">Email:</label>
              <input
                type="email"
                id="email"
                v-model="formData.email"
                required
              />
            </div>
          </div>

          <label for="message">Wiadomość:</label>
          <textarea
            id="message"
            v-model="formData.message"
            rows="4"
            required
          ></textarea>

          <button type="submit" class="btnForm" :disabled="isSubmitting">
            Wyślij
          </button>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      formData: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
      isSubmitting: false,
      statusMessage: "",
    };
  },
  methods: {
    async submitForm() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      try {
        const response = await axios.post(
          "https://domy.mis.info.pl/admin/mailsend",
          this.formData
        );
        this.statusMessage = response.data.message;

        // Czyszczenie pól formularza
        this.formData = {
          name: "",
          email: "",
          message: "",
        };
      } catch (error) {
        this.statusMessage = "Wystąpił błąd podczas wysyłania formularza.";
      } finally {
        this.isSubmitting = false; // Ponowna aktywacja przycisku
      }
    },
  },
};
</script>
<style>
input,
textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
  width: 100%;
}

.contactFormWrapper {
  display: flex;
  justify-content: space-around;

  form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;

    .input-group {
      display: flex;
      justify-content: space-between;
      column-gap: 20px;

      & input {
        flex: 1;
        margin-right: 10px;
      }

      & input:last-child {
        margin-right: 0;
      }
    }
    .btnForm {
      background: var(--secondary-color);
      color: var(--white-color);
      padding: 20px;
      margin: 0 auto 25px;
      border-radius: 20px;
      border: none;
      width: 175px;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      transition: background-color 0.5s ease;
    }

    .btnForm:hover {
      background-color: var(--black-color);
    }
  }
}

.messageStatus {
  text-align: center;
  padding: 20px;
  margin-bottom: 40px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  font-weight: 600;
}

@media screen and (max-width: 567px) {
  form {
    width: 100%;

    .input-group {
      flex-direction: column;

      & input {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
