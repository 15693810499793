<template>
  <section class="container">
    <div class="sectionHeader">
      <div class="sectionHeading">
        <h3>Rekomendacje naszych klientów</h3>
      </div>
    </div>
    <div class="CustomerReview">
      <div
        class="CustomerReviewItem"
        data-aos="fade-right"
        data-aos-delay="250"
        v-for="item in respectList"
        :key="item.id"
      >
        <span> <i class="ri-edit-fill"></i></span>
        <h5 v-html="item.title"></h5>
        <p v-html="item.description"></p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "CustomerReviewsApp",
  data() {
    return {
      respectList: [],
    };
  },
  methods: {
    async getListRespect() {
      try {
        const response = await this.$axios.get(
          "?api&action=getListCustomerReview"
        );
        this.respectList = response.data;
      } catch (error) {
        console.error("Błąd:", error);
      }
    },
  },
  mounted() {
    this.getListRespect();
  },
  watch: {},
  computed: {},
};
</script>
<style>
.CustomerReview {
  display: flex;
  flex-wrap: wrap; /* Pozwala na automatyczne przechodzenie do nowej linii */
  justify-content: center; /* Kolumny zaczynają się od lewej */
  gap: 10px; /* Odstępy między kolumnami i wierszami */
  width: 100%; /* Maksymalna szerokość kontenera */
  max-width: 1200px; /* Ograniczenie szerokości dla dużych ekranów */
}
.CustomerReviewItem {
  flex: 1 1 calc(25% - 10px); /* Kolumna zajmuje 25% szerokości z uwzględnieniem odstępów */
  max-width: calc(
    25% - 10px
  ); /* Zapewnienie, że pojedyncza kolumna też ma 25% */
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box; /* Uwzględnia padding w szerokości kolumny */

  i {
    font-size: 100px;
    color: var(--secondary-color);
  }

  h5 {
    font-size: 18px;
    text-transform: uppercase;
    padding-bottom: 15px;
    line-height: 24px;
  }

  p {
    font-size: 13px;
    line-height: 25px;
  }
}

@media screen and (max-width: 1024px) {
  .CustomerReviewItem {
    flex: 1 1 calc(50% - 10px); /* Kolumny zajmują 50% szerokości na mniejszych ekranach */
    max-width: calc(50% - 10px);
  }
}

@media screen and (max-width: 567px) {
  .CustomerReviewItem {
    flex: 1 1 100%; /* Kolumny zajmują 100% szerokości na bardzo małych ekranach */
    max-width: 100%;
  }
}
</style>
