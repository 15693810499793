<template>
  <footer>
    <div class="footer container">
      <div class="footerLogo"><img src="../assets/logo1.png" /></div>
      <div class="footerContact">
        <h5>Kontakt</h5>
        <div>
          <p>
            e-mail:
            <a :href="`mailto:${contactList[0]?.email}`">{{
              contactList[0]?.email
            }}</a>
            <br />
            tel. {{ contactList[0]?.phone }} <br />
            <a :href="`https://${contactList[0]?.www}`">{{
              contactList[0]?.www
            }}</a>
          </p>
        </div>
      </div>
      <div class="footerInvestor">
        <div class="footerInvestorBox">
          <p>
            <strong> {{ contactList[0]?.company }}</strong>
            <br />
            {{ contactList[0]?.street }}<br />
            {{ contactList[0]?.codePost }} {{ contactList[0]?.city }}
          </p>
        </div>
      </div>
    </div>
    <div class="copyright">Wszystkie prawa zastrzeżone © 2024 Domy MIŚ</div>
  </footer>
</template>
<script>
export default {
  name: "FooterApp",
  data() {
    return {
      contactList: [],
    };
  },
  methods: {
    async getListContact() {
      try {
        const response = await this.$axios.get("?api&action=getListContact");
        this.contactList = response.data;
      } catch (error) {
        console.error("Błąd:", error);
      }
    },
  },
  mounted() {
    this.getListContact();
  },
  watch: {},
  computed: {},
};
</script>
<style>
/*=============== FOOTER ===============*/
footer {
  background-color: var(--black-color);
  color: var(--white-color);
  .footer {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 68px 0px;
    .footerLogo {
      flex: 1;

      & img {
        max-width: 65%;
      }
    }
    .footerContact {
      flex: 1;
      & p {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 1px;
        margin: 0 0 25px;
        line-height: 30px;
        width: 100%;
      }
    }

    .footerInvestor {
      flex: 1;
      .footerInvestorBox {
        margin-top: 39px;
        & p {
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 1px;
          margin: 0 0 25px;
          line-height: 30px;
          width: 100%;
        }
      }
    }
  }
  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0px;
    font-size: 12px;
    width: 100%;
  }
}

.footerContact h5,
.footerInvestor h5 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 16px;
  letter-spacing: 4px;
  font-weight: 500;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 567px) {
  footer {
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 0px;
      flex-direction: column;
      text-align: center;

      .footerLogo {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
