<template>
  <section class="container">
    <div class="sectionHeader container">
      <div class="sectionHeading">
        <h3>Dane kontaktowe</h3>
      </div>
    </div>
    <div class="contactWraper container">
      <div class="contactBox" data-aos="fade-right" data-aos-delay="250">
        <i class="ri-home-5-line"></i>
        <h5>Adres</h5>
        <div>
          <p>
            <strong>{{ contactList[0]?.company }}</strong> <br />
            {{ contactList[0]?.street }}<br />
            {{ contactList[0]?.codePost }} {{ contactList[0]?.city }}
          </p>
        </div>
      </div>
      <div class="contactBox" data-aos="fade-right" data-aos-delay="255">
        <i class="ri-contacts-line"></i>
        <h5>Dane kontaktowe</h5>
        <div>
          <p>
            e-mail:
            <a :href="`mailto:${contactList[0]?.email}`">{{
              contactList[0]?.email
            }}</a
            ><br />
            tel. {{ contactList[0]?.phone }}<br />
            <a :href="`https://${contactList[0]?.www}`">{{
              contactList[0]?.www
            }}</a>
          </p>
        </div>
      </div>
      <div class="contactBox" data-aos="fade-right" data-aos-delay="260">
        <i class="ri-timer-line"></i>
        <h5>Godziny otwarcia</h5>
        <div>
          <p>{{ contactList[0]?.openingHours }}</p>
        </div>
      </div>
    </div>
  </section>
  <div>
    <iframe
      v-if="iframeSrc"
      :src="iframeSrc"
      id="mapFrame"
      style="border: 0"
      loading="lazy"
      width="100%"
      height="300px"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "DetailContacttApp",
  data() {
    return {
      contactList: [],
      iframeSrc: "",
    };
  },
  methods: {
    // Wywołanie funkcji, aby załadować mapę

    async getListContact() {
      try {
        const response = await this.$axios.get("?api&action=getListContact");
        this.contactList = response.data;
        this.iframeSrc = response.data[0].mapsLink;
      } catch (error) {
        console.error("Błąd:", error);
      }
    },
  },
  mounted() {
    this.getListContact();
  },
  watch: {},
  computed: {},
};
</script>
<style>
/*=============== FOOTER ===============*/

.contactWraper {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  text-align: center;

  .contactBox {
    flex: 1;
    & i {
      font-size: 60px;
      margin-bottom: 20px;
    }
    & p {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1px;
      margin: 0 0 25px;
      line-height: 30px;

      & a {
        color: #000;
      }
    }
  }
}

.contactBox h5 {
  text-transform: uppercase;
  margin: 15px 0px;
  font-size: 16px;
  letter-spacing: 4px;
  font-weight: 500;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 567px) {
  .contactWraper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    flex-direction: column;
    text-align: center;
  }
}
</style>
